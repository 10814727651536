<template>
  <div class="home-container">
    <div class="banner">
      <div class="page-title">
        웹/앱 플랫폼 창업도<span class="primary">.</span> 쇼핑몰 창업처럼
        <div class="page-title-bold">누구나 쉽고 간단하게</div>
      </div>
      <div class="flex-center">
        <div class="banner-white-box">
          수 많은 사람들이 시도하고 있는 플랫폼 서비스 창업,<br />
          왜 아직도 <span class="primary">모든 예비 창업자들이 처음부터 동일한 시행착오를 반복</span>해야 하는
          걸까요?<br />
          <img src="/static/icon/ic_arrow-down.svg" alt="" style="width: 28px; margin-top: 20px" />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="h4 main text-center" style="padding: 80px 0 32px 0">내 창업단계에 맞는 상품으로 시작하세요.</div>
    </div>
    <div class="sticky">
      <div class="container" style="padding-bottom: 40px">
        <div v-for="(item, idx) in menu" :key="`menu-${idx}`" class="flex-align">
          <!--<div :class="{'item-menu-selected': item.id === selectedMenu}"-->
          <div class="item-menu unselect" @click="clickMenu(item)">
            <div>
              <svg-icon class="svg-icon" :icon="item.icon" color="sub2"></svg-icon>
              <div class="body2">{{ item.name }}</div>
            </div>
          </div>
          <div class="line-vertical" v-if="idx < menu.length - 1"></div>
        </div>
      </div>
    </div>

    <a class="anchor" id="menu-1"></a>
    <div id="container-1" class="container menus" style="padding-top: 100px">
      <div class="text-center">
        <img src="/static/icon/lightbulb_on.svg" />
        <div class="body2-medium primary" style="margin: 8px 0 12px 0">아이디어 테스트 단계</div>
        <div class="h4 sub">
          1개월 단위로 플랫폼 서비스를 운영해보고 싶은 창업자를 위한
          <span class="primary">플랫폼파이<br />무료 체험해보세요!</span>
        </div>
        <div class="body2 sub3 margin-top-20">
          블럭 단위로 디자인 편집을 할 수 있는 빌더가 포함되어 있어<br />
          간단히 내 아이디어에 맞게 설정하고 런칭할 수 있어요.
        </div>
      </div>
      <div class="grid-4 margin-top-40">
        <card-product v-for="theme in themes" :key="`theme-${theme.id}`" :card="theme" isTheme></card-product>
      </div>
    </div>

    <div
      class="flex-justify margin-top-40"
      style="background: linear-gradient(180deg, rgba(250, 250, 250, 0) 36.46%, #efefef 100%)">
      <div class="platformfy-info-container">
        <div class="img-slider">
          <div class="img">
            <img v-for="idx in 30" :key="`img-slider-${idx}`" src="/static/img/home/info_img_full_pc.png" />
          </div>
        </div>
        <div>
          <div class="subtitle3 main" style="line-height: 36px">이미 많은 고객분들께서 플랫폼파이로</div>
          <div class="h5 main" style="line-height: 36px">초기 아이디어를 검증하고,<br />투자를 유치하고 계십니다!</div>
          <div class="margin-top-40">
            <a href="https://platformfy.com/theme_market" target="_blank">
              <button
                class="button is-main body2-medium flex-align"
                style="background-color: transparent; gap: 0 10px; width: 290px">
                테마 더보기 <svg-icon icon="u_arrow-up-right" :width="18" :height="18" color="main"></svg-icon>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>

    <a class="anchor" id="menu-2"></a>
    <div id="container-2" class="menus menu2">
      <div class="container">
        <div class="text-center">
          <img src="/static/icon/fi_flag_primary.svg" />
          <div class="body2-medium primary" style="margin: 8px 0 12px 0">시장 선점 단계</div>
          <div class="h4 sub">
            원하는 대로 수정제작을 하고 싶은 창업자를 위한 <span class="primary">제작 스토어</span>
          </div>
          <div class="body2 sub3 margin-top-20">
            유저 니즈에 맞게 부분 수정 개발을 해서 런칭할 수 있어요. <br />
            플랫폼파이 테마로 먼저 테스트 하고 추가 개발 하는 것도 가능해요.
          </div>
        </div>
        <div class="grid-3 margin-top-40">
          <card-product v-for="skin in skins" :key="`skin-${skin.id}`" :card="skin"></card-product>
        </div>
        <div class="margin-top-40 flex-center">
          <button
            class="button is-main body2 flex-center"
            style="width: 360px"
            @click="routerPush('/template_category?id=60')">
            <div class="flex-align" style="gap: 14px">
              스킨 더보기 <svg-icon icon="u_arrow-right" :width="24" :height="24" color="main"></svg-icon>
            </div>
          </button>
        </div>
      </div>
    </div>

    <a class="anchor" id="menu-3"></a>
    <div id="container-3" class="menus menu3 position-relative">
      <img class="img-prime-back" src="/static/img/home/img_prime_back2.png" alt="prime" />
      <div class="container menu3-content">
        <div>
          <img src="/static/icon/chart_mixed.svg" />
          <div class="body2-medium primary" style="margin: 12px 0 20px 0">비즈니스 단계</div>
          <div class="h4 white">완전 맞춤제작을 원하신다면?</div>
          <div class="size-28 primary weight-700">Prime</div>
          <div class="body2 sub5">
            이미 매출이 발생하고 있는 비즈니스를 온라인 서비스로 런칭할 때 이용해요.<br />
            높은 완성도가 무엇보다도 중요해요.
          </div>
          <div class="flex" style="margin-top: 28px; gap: 12px">
            <button class="button body2 is-primary-light" style="width: 180px" @click="routerPush('/prime')">
              자세히 보기
            </button>
            <button class="button body2 is-primary" style="width: 180px" @click="routerPush('/prime#inquiry')">
              신청하기
            </button>
          </div>
        </div>
        <div class="slick-content">
          <div class="grid-slick">
            <img class="unselect" src="/static/icon/arrow_back_ios.svg" @click="$refs.slick.prev()" />
            <slick ref="slick" :options="slickOptions" @afterChange="onChange">
              <div v-for="prime in primes" :key="`prime-ex-${prime.id}`">
                <div
                  class="slick-img"
                  :style="`background-image:url(/static/img/prime-portfolio${prime.id}.png)`"></div>
                <div class="flex-center" v-if="prime.url !== ''">
                  <a :href="prime.url" target="_blank">
                    <div class="flex margin-top-20 margin-bottom-20 primary">
                      사이트 바로가기 <i class="material-icons">keyboard_arrow_right</i>
                    </div>
                  </a>
                </div>
              </div>
            </slick>
            <img class="unselect" src="/static/icon/arrow_forward_ios.svg" @click="$refs.slick.next()" />
          </div>
          <div class="indicators">
            <div
              class="indicator unselect"
              :class="{ 'indicator-current': idx === currentSlide }"
              @click="$refs.slick.goTo(idx)"
              v-for="(prime, idx) in primes"
              :key="`indicator-${prime.id}`"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="menus menu5">
      <div class="container text-center">
        <div class="subtitle3 main margin-bottom-16">
          런치팩 솔루션은 일반적으로 많이 사용되는<br />기술스펙으로 구성되어 있습니다.
        </div>
        <div class="body2 sub3 margin-bottom-40">
          언제든 고도화가 가능하고 투자유치나 사업규모 확장으로 독립이 필요한 경우 별도 계약을 통해<br />
          프론트엔드 소스코드 공유 및 백엔드 API 전송이 가능합니다.
        </div>
        <img src="/static/img/home/img_info_source.png" />
      </div>
    </div>

    <a class="anchor" id="menu-4"></a>
    <div id="container-4" class="menus menu4">
      <div class="container">
        <div class="box-app-market-desc">
          <img src="/static/icon/rocket.svg" />
          <div class="body2-medium primary" style="margin: 12px 0 20px 0">서비스 런칭 단계</div>
          <div class="h5 sub">
            런칭 준비가 끝났다면?<br />앱포팅 서비스로 내 앱을 구글플레이,<br />앱스토어에 올려보세요!
          </div>
          <div class="body2 sub3" style="margin: 20px 0 48px 0">
            앱포팅 및 앱마켓 등록 대행 부터 GA(Google Analytics)등록 등<br />
            비즈니스 운영에 필요한 다양한 부가서비스를 활용해 내 서비스를<br />본격적으로 사업화 할 수 있습니다.
          </div>
          <button
            class="button body2 is-primary-light flex-align"
            style="width: 180px"
            @click="routerPush('/additional_service')">
            부가서비스 더보기 <img src="/static/icon/u_arrow-right.svg" style="margin-left: 8px" class="svg-primary" />
          </button>
        </div>
        <div class="menu4-items">
          <card-product-simple v-for="card in plugins" :key="`plugin-${card.id}`" :card="card"></card-product-simple>
        </div>
      </div>
    </div>

    <a class="anchor" id="menu-5"></a>
    <div id="container-5" class="container menus menu5">
      <div class="text-center">
        <img src="/static/icon/fi_tool_primary.svg" alt="" />
        <div class="body2-medium primary" style="margin: 12px 0">인하우스 제작 단계</div>
        <div class="h4 sub">디자이너, 개발자를 위한 <span class="primary">웹/앱 템플릿 소스 스토어</span></div>
        <div class="body2 sub3 margin-top-20">직접 제작할 수 있는 제작자나 개발팀이 있는 창업팀이 이용할 수 있어요</div>
      </div>
      <div class="grid-3 margin-top-40">
        <card-product v-for="card in templates" :key="`template-${card.id}`" :card="card"></card-product>
      </div>
      <div class="margin-top-48 flex-center">
        <button
          class="button is-main body2 flex-center"
          style="width: 360px"
          @click="routerPush('/template_category?id=70')">
          <div class="flex-align" style="gap: 14px">
            템플릿 더보기 <svg-icon icon="u_arrow-right" :width="24" :height="24" color="main"></svg-icon>
          </div>
        </button>
      </div>
    </div>

    <a class="anchor" id="menu-6"></a>
    <div id="container-6" class="menus container">
      <div
        class="unselect menu6 flex-align"
        style="justify-content: space-between"
        @click="routerPush('/template_market')">
        <!-- <img src="/static/icon/fi_x-octagon_fill.svg">
        <div class="body2-medium main" style="margin:8px 0 12px 0">비지니스 종료 단계</div> -->
        <div>
          <div class="body0 main">대표님! 예산을 들여 제작한 결과물을 활용하지 않고 묵혀두고 계시나요?</div>
          <div class="flex-align margin-top-4" style="gap: 0 8px">
            <div class="h5 main">예비창업팀에게 공유하고 수익창출 하세요</div>
            <img src="/static/icon/img_arrow_circle_right.svg" style="width: 32px; height: 32px" />
          </div>
        </div>
        <img src="/static/img/img_gold_2.png" style="width: 292px; height: 214px" />
        <!-- <div class="flex-center" style="margin-top:32px">
          <button class="button is-primary flex-align size-16" style="width:280px;height:48px" @click="routerPush('/template_inquiry')">
            <img src="/static/icon/img_enelope.png" style="width:24px;height:24px;margin-right:6px"
            > 내 소스 파일 판매 신청하기</button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '../component/SvgIcon';
import CardProduct from '../component/CardProduct';
import Slick from 'vue-slick';
import CardProductSimple from '../component/CardProductSimple';

export default {
  name: 'HomePc',
  components: {
    CardProductSimple,
    CardProduct,
    SvgIcon,
    Slick,
  },
  created() {
    this.getData();
  },
  activated() {
    this.selectedMenu = 0;
    this.$nextTick(() => {
      //this.controller = new AbortController();
      //const { signal } = this.controller;
      /*window.addEventListener('scroll', this.scrollListener, { signal });*/

      if (this.$refs.slick) {
        this.$refs.slick.destroy();
        this.$nextTick(() => {
          this.$refs.slick.create();
        });
      }
    });
  },
  deactivated() {
    //this.controller.abort();
  },
  data() {
    return {
      controller: undefined,
      selectedMenu: 0,
      menu: [
        {
          id: 1,
          name: '아이디어 테스트 단계',
          icon: 'light_bulb',
        },
        {
          id: 2,
          name: '시장 선점 단계',
          icon: 'flag',
        },
        {
          id: 3,
          name: '비즈니스 단계',
          icon: 'chart',
        },
        {
          id: 4,
          name: '서비스 런칭 단계',
          icon: 'rocket',
        },
        {
          id: 5,
          name: '인하우스 제작 단계',
          icon: 'tool',
        },
        {
          id: 6,
          name: '비지니스 종료 단계',
          icon: 'octagon',
        },
      ],
      themes: [],
      skins: [],
      plugins: [],
      templates: [],
      slickOptions: {
        centerMode: false,
        variableWidth: false,
        infinite: true,
        autoplay: false,
        centerPadding: '20px',
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 300,
        arrows: false,
      },
      currentSlide: 0,
      primes: [
        { id: 2, url: 'https://garamfood.net' },
        { id: 3, url: '' },
        { id: 1, url: '' },
        /*{ id: 1, url: 'https://weflower.co.kr' },*/
      ],
      popupMarket: undefined,
    };
  },
  methods: {
    onMessageMarket(e) {
      if (e.data.close) {
        this.popupMarket.close();
        this.removeMessageEvent();
        if (e.data.redirectUrl) {
          setTimeout(() => {
            window.open(e.data.redirectUrl);
          }, 300);
        }
      }
    },
    addMessageEvent() {
      window.addEventListener('message', this.onMessageMarket);
    },
    removeMessageEvent() {
      window.removeEventListener('message', this.onMessageMarket);
    },
    clickMoreApp() {
      let url = '/market/list';
      let width = 1231;
      let height = 760;
      this.popupMarket = window.open(
        url,
        'appMarket',
        `width=${width}, height=${height}, top=0, left=0, menubar=no, toolbar=no, titlebar=yes`
      );
      this.addMessageEvent();
    },
    onChange(event, slick, currentSlide) {
      this.currentSlide = currentSlide;
    },
    scrollListener() {
      const html = document.getElementsByTagName('html')[0].getBoundingClientRect();
      if (html.y === 0) {
        this.selectedMenu = 0;
      } else if (html.height + html.y - window.innerHeight < 300) {
        this.selectedMenu = this.menu.length;
      } else {
        const menus = document.querySelectorAll('.menus');
        menus.forEach((el) => {
          let rect = el.getBoundingClientRect();
          if (rect.top < 180 && rect.height + rect.top > 0) {
            this.selectedMenu = Number(el.id.replace('container-', ''));
          }
        });
      }
    },
    clickMenu(item) {
      location.href = '#menu-' + item.id;
      setTimeout(() => {
        this.selectedMenu = item.id;
      }, 100);
    },
    colorValue(item) {
      return item.id === this.selectedMenu ? 'primary' : 'sub2';
    },
    async getData() {
      let params = {
        category: 59,
        fields:
          'id,name,simple_desc,price,img,partner.img,partner.name,category1,category2,preview_url,visit,rate,params,theme_version',
        page_num: 1,
        page_length: 7,
        ordering: 'priority',
      };
      let res = await this.$axios.get('user/0/mapping/product', { params: params });
      this.themes = res.data.data;
      // skins
      params.category = 60;
      params.page_length = 3;
      res = await this.$axios.get('user/0/mapping/product', { params: params });
      this.skins = res.data.data;

      params.category = 64;
      params.page_length = 4;
      res = await this.$axios.get('user/0/mapping/product', { params: params });
      this.plugins = res.data.data;

      params.category = 70;
      params.page_length = 3;
      res = await this.$axios.get('user/0/mapping/product', { params: params });
      this.templates = res.data.data;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'
@keyframes img-slider
  from
    transform translateX(0)
  to
    transform translateX(-100%)

.img-slider
  width 677px
  overflow-x hidden
  .img
    animation img-slider 1200s linear infinite
    display flex
    width 53100px
  img
    height 280px
  .img:hover
    animation-play-state paused



.home-container
  margin-top 64px

.platformfy-info-container
  padding 60px 0 100px
  display flex
  align-items center
  gap 0 80px

.info-img-wrap
  display grid
  grid-template-columns repeat(4, 1fr)
  gap 0 50px

.banner
  background url(/static/img/home/background_main_banner.png)
  background-repeat no-repeat
  background-position 50%
  background-size cover
  text-align center

.page-title
  padding 144px 0 90px 0
  font-weight 300

.page-title
.page-title-bold
  font-size 48px
  line-height 72px
  color $main
.page-title-bold
  font-weight 700


.banner-white-box
  background-color white
  border-top-left-radius 20px
  border-top-right-radius 20px
  padding 28px 40px 12px 40px
  font-size 16px
  line-height 25.6px


.sticky
  /*position sticky*/
  top 64px
  z-index 100
  background-color white
  box-shadow 0 5px 10px 0 rgba(0, 0, 0, 0.05)
  .container
    display grid
    grid-template-columns repeat(6, 1fr)

.item-menu
  width 192px
  height 152px
  text-align center
  color $sub2
  display flex
  align-items center
  justify-content center
  border-bottom 2px solid transparent
.item-menu-selected
  color $primary
  border-bottom 2px solid $primary

.line-vertical
  width 1px
  background-color $gray1
  height 32px

.svg-icon
  height 32px
  margin-bottom 12px

.menu1
.menu2
.menu4
.menu5
  padding 100px 0

.menu2
  border-top 1px solid $gray3

.menu3
  height 562px
  overflow hidden
  background-color $main
  display flex
  align-items center
.menu3-content
  z-index 1
  display flex
  gap 60px

.menu4
  background-color $gray3
  .container
    display grid
    grid-template-columns repeat(2, 1fr)
    grid-gap 40px
    .menu4-items
      display grid
      grid-template-columns repeat(2, 1fr)
      grid-gap 24px
    .box-app-market-desc
      background-color white
      padding 40px

.menu6
  background-color $subcolor50
  padding 0px 40px
  text-align center
  margin-bottom 100px
  border-radius 12px
  overflow hidden
  height 192px

.box-error-50
  display flex
  align-items center
  background-color $error50
  color $error
  font-size 20px
  padding 12px 60px
  border-radius 50px
  img
    margin-right 12px
  b
    margin-left 4px

.grid-4
  display grid
  grid-template-columns repeat(4, 1fr)
  grid-gap 36px
.grid-3
  display grid
  grid-template-columns repeat(3, 1fr)
  grid-gap 36px

.box-primary-light2
  background-color $primary-light2
  color $primary
  gap 12px
  display flex
  align-items center
  justify-content center
  padding 12px
  border-radius 8px

  .emoticon
    width 40px
    height 40px

a.anchor
  top -62px
  position relative

.img-prime-back
  position absolute
  top -112px
  left calc(50% - 709px)
  width 915px

.grid-slick
  display grid
  grid-template-columns 1fr 453px 1fr
  align-items center
  grid-gap 25px
.slick-img
  height 254px
  width 100%
  background-repeat no-repeat
  background-position center
  background-size contain
.indicators
  display flex
  align-content center
  justify-content center
  gap 12px
  .indicator
    width 6px
    height 6px
    border-radius 100%
    background-color rgba(255, 255, 255, 0.5)
  .indicator-current
    background-color #ffffff
</style>
